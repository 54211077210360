<template>
  <div class="newcomer-container">
    <div class="contents">
      <!-- is-finish, 수료시 표시 -->
      <div class="summary">
        <div class="summary-header">
          <h5>직무연수종합평가</h5>
          <div class="summary-badge">미수료</div>
        </div>
        <p class="summary-context">
          <span class="current">40.64</span><span class="total">/60점</span>
        </p>
      </div>
      <div class="newcomer-content">
        <div class="content-header">
          <h6 class="title">외국환업무</h6>
          <p class="desc">외국환업무(한국금융연수원 통신연수) 수강</p>
          <p class="sub-desc">수료인원 187 / 231</p>
          <p class="sub-desc">수료일 2022.12.25</p>
        </div>
        <div class="segment-box segment-box-outline">
          <div class="contents-list">
            <div class="segment-contents">
              <h5>81.28점</h5>
              <p>획득점수</p>
            </div>
            <div class="segment-contents">
              <h5>40.64점</h5>
              <p>환산점수 (50%)</p>
            </div>
          </div>
        </div>
      </div>
      <div class="newcomer-content">
        <div class="content-header">
          <h6 class="title">지식e-러닝</h6>
          <p class="desc">최소 2년 의무참가/년도별 60%응시시, 환산점수 반영</p>
          <p class="sub-desc">총점순위 5 / 231</p>
        </div>
        <div class="segment-box segment-box-outline">
          <div class="contents-list">
            <div class="segment-contents">
              <h5>94.77점</h5>
              <p>획득점수</p>
            </div>
            <div class="segment-contents">
              <h5>-</h5>
              <p>환산점수 (50%)</p>
            </div>
          </div>
          <div class="kb-table segment-table">
            <table>
              <colgroup>
                <col>
              </colgroup>
              <thead>
              <tr>
                <th><span class="th-title">연도</span></th>
                <th><span class="th-title">응시횟수</span></th>
                <th><span class="th-title">평균점수</span></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><span class="td-text">2021</span></td>
                <td><span class="td-text">12/12</span></td>
                <td><span class="td-text">92.86점</span></td>
              </tr>
              <tr>
                <td><span class="td-text">2022</span></td>
                <td><span class="td-text">3/6</span></td>
                <td><span class="td-text">96.67점</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="contents">
      <div class="summary is-finish">
        <div class="summary-header">
          <h5>핵심자격증</h5>
          <div class="summary-badge">수료</div>
        </div>
        <p class="summary-context">
          <span class="current">{{lcsnTotCnt}}</span><span class="total">/2개</span>
        </p>
      </div>
      <div class="newcomer-content">
        <div class="content-header">
          <h6 class="title">필수자격증</h6>
          <p class="desc">3개 중 1개 이상 취득 (입행전 취득 인정)</p>
          <p class="sub-desc">취득인원 208 / 231</p>
        </div>
        <div class="kb-table kb-table-round">
          <table>
            <colgroup>
              <col style="width:50%">
              <col>
              <col>
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">자격증명</span></th>
              <th colspan="2"><span class="th-title">자격증 취득일</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, idx) in manLcsnList" :key="idx" :class="{'is-finish' : item.lcsnCnt > 0}">
              <td><span class="td-text">{{item.lcsnNm}}</span></td>
              <td><span class="td-text">{{item.lcsnRegYmd != null ? item.lcsnRegYmd : '-'}}</span></td>
              <td :class="{'is-checked' : item.lcsnCnt > 0}"><span class="icon-gold-check"></span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="newcomer-content">
        <div class="content-header">
          <h6 class="title">선택자격증</h6>
          <p class="desc">8개 중 1개 이상 취득 (입행전 취득 불인정)</p>
          <p class="sub-desc">취득인원 191 / 231</p>
        </div>
        <div class="kb-table kb-table-round">
          <table>
            <colgroup>
              <col style="width:50%">
              <col>
              <col>
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">자격증명</span></th>
              <th colspan="2"><span class="th-title">자격증 취득일</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, idx) in elecLcsnList" :key="idx" :class="{'is-finish' : item.lcsnCnt > 0}">
              <td><span class="td-text">{{item.lcsnNm}}</span></td>
              <td><span class="td-text">{{item.lcsnRegYmd != null ? item.lcsnRegYmd : '-'}}</span></td>
              <td :class="{'is-checked' : item.lcsnCnt > 0}"><span class="icon-gold-check"></span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- content-section:공지사항 -->
  <div class="content-section section-margin">
    <header class="section-header">
      <h3 class="title">공지사항</h3>
      <div class="header-sub"><router-link :to="{name: 'JuniorNotice', params: {boardType: 'training'}}" class="more-link me-4">전체보기</router-link></div>
    </header>
    <div class="notice-list-container">
      <div class="board-list-container">
        <ul class="board-list">
          <li class="board-list-item">
            <article class="board-row">
              <router-link :to="{name: 'JuniorNoticeView', params: {comNtcSn: 1, boardType: 'newZ'}}" class="board-link"></router-link>
              <div class="board-column column-index"><em class="text">20</em></div>
              <div class="board-column column-title"><span class="title">L1 신입행원사후연수 (KB 41기) 안내문</span></div>
              <div class="board-column column-date"><span class="text">2021.6.10 14:40</span></div>
              <div class="board-column column-hit"><span class="text">50336 조회</span></div>
            </article>
          </li>
          <li class="board-list-item">
            <article class="board-row">
              <router-link :to="{name: 'JuniorNoticeView', params: {comNtcSn: 2, boardType: 'newZ'}}" class="board-link"></router-link>
              <div class="board-column column-index"><em class="text">19</em></div>
              <div class="board-column column-title"><span class="title">L1 신입행원사후연수 (KB 41기) 안내문</span></div>
              <div class="board-column column-date"><span class="text">2021.6.10 14:40</span></div>
              <div class="board-column column-hit"><span class="text">50336 조회</span></div>
            </article>
          </li>
          <li class="board-list-item">
            <article class="board-row">
              <router-link :to="{name: 'JuniorNoticeView', params: {comNtcSn: 3, boardType: 'newZ'}}" class="board-link"></router-link>
              <div class="board-column column-index"><em class="text">18</em></div>
              <div class="board-column column-title"><span class="title">L1 신입행원사후연수 (KB 41기) 안내문</span></div>
              <div class="board-column column-date"><span class="text">2021.6.10 14:40</span></div>
              <div class="board-column column-hit"><span class="text">50336 조회</span></div>
            </article>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {onMounted, ref} from "vue";
import {
  ACT_GET_JUNIOR_TRANING_ELECTIVE_LCSN_LIST,
  ACT_GET_JUNIOR_TRANING_MANDATORY_LCSN_LIST,
} from "@/store/modules/my/my";
import {getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";

export default {
  name: "JuniorTraining",
  setup(){
    const store = useStore();
    const sessionId = store.state.auth.session.lrnerId;
    const manLcsnList = ref([]);
    const elecLcsnList = ref([]);
    const lcsnTotCnt = ref(0);

    onMounted(() => {
      getMandatoryLcsnList();
      getElectiveLcsnList();
    })

    // 필수자격증
    const getMandatoryLcsnList = async () => {
      await store.dispatch(`my/${ACT_GET_JUNIOR_TRANING_MANDATORY_LCSN_LIST}`, {
        aplcntId: sessionId,
      }).then(res => {
        if(lengthCheck(res)){
          manLcsnList.value = getItems(res);
          manLcsnList.value.filter(x => { if(x.lcsnCnt != null && x.lcsnCnt != 0) lcsnTotCnt.value++ })
        }
      }).catch(err => {
        console.error(err)
      })
    }

    // 선택자격증
    const getElectiveLcsnList = async () => {
      await store.dispatch(`my/${ACT_GET_JUNIOR_TRANING_ELECTIVE_LCSN_LIST}`, {
        aplcntId: sessionId,
      }).then(res => {
        if(lengthCheck(res)){
          elecLcsnList.value = getItems(res);
          elecLcsnList.value.filter(x => { if(x.lcsnCnt != null && x.lcsnCnt != 0) lcsnTotCnt.value++ })
        }
      }).catch(err => {
        console.error(err)
      })
    }

    return {
      manLcsnList,
      elecLcsnList,
      sessionId,
      lcsnTotCnt,
      timestampToDateFormat,
    }
  }
}
</script>