<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-newcomer">
    <!-- main-header -->
    <div class="main-header">
      <lxp-main-header :show-breadcrumb="true" :show-title="false">
        <template v-slot:action>
          <div class="header-tab">
            <nav class="kb-nav-round-tabs">
              <ul class="kb-nav-list">
                <li class="kb-nav-item">
                  <a href="javascript:" class="kb-nav-link" :class="{'is-active' : menu === 'juniorTraining'}" @click="menu = 'juniorTraining'"><span class="kb-nav-text">사후연수</span></a>
                </li>
                <li class="kb-nav-item">
                  <a href="javascript:" class="kb-nav-link" :class="{'is-active' : menu === 'juniorNewZ'}" @click="menu = 'juniorNewZ'"><span class="kb-nav-text">NewZ</span></a>
                </li>
              </ul>
            </nav>
          </div>
          <!-- main-header > header-title -->
          <div class="header-title">
            <h2 class="title">{{ menu === 'juniorTraining' ? '신입행원사후연수' : '신입행원 NewZ' }}</h2>
            <p v-if="menu === 'juniorNewZ'" class="subtitle">상위 14.2%</p>
            <!-- badge badge-primary로 수료시 표시 -->
            <div class="title-badges">
              <div v-if="menu === 'juniorTraining'" class="badge badge-silver"><span class="badge-text">미수료</span></div>
              <div v-else class="badge badge-silver"><span class="badge-text">미선정</span></div>
            </div>
            <p v-if="menu === 'juniorTraining'" class="subtext">L1 신입행원사후연수 (KB 41기) ・ 연수기간 2021.6.25.금 - 2021.7.25.화</p>
            <p v-else class="subtext">신입행원육성프로그램(NewZ 36기) ・ 연수기간 2022.6.25.금 - 2022.7.25.화</p>
          </div>
        </template>
      </lxp-main-header>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">
      <JuniorTraining v-if="menu === 'juniorTraining'"/>
      <JuniorNewZ v-if="menu === 'juniorNewZ'"/>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import LxpMainHeader from "@/components/common/LxpMainHeader";
import JuniorTraining from "@/components/junior/JuniorTraining";
import JuniorNewZ from "@/components/junior/JuniorNewZ";
import {ref} from "vue";
export default {
  name: "Training",
  components: {JuniorNewZ, JuniorTraining, LxpMainHeader},
  setup() {
    const menu = ref('juniorTraining');

    return {
      menu
    }
  }
}
</script>