<template>
  <!-- content-section:총점 -->
  <article class="content-section">
    <header class="section-header"><h3 class="title">총점</h3></header>
    <div class="symbol-card-wrap">
      <!-- symbol-card -->
      <div class="symbol-card symbol-card-primary">
        <div class="card-icon"><i class="icon-final"></i></div>
        <div class="card-content">
          <div class="content-title">85.0점</div>
          <div class="content-subhead">최종점수</div>
        </div>
      </div>
      <!-- symbol-card -->
      <div class="symbol-card">
        <div class="card-icon"><i class="icon-activity"></i></div>
        <div class="card-content">
          <div class="content-title">75.0점</div>
          <div class="content-subhead">학습활동 점수</div>
        </div>
      </div>
      <!-- symbol-card -->
      <div class="symbol-card">
        <div class="card-icon"><i class="icon-add"></i></div>
        <div class="card-content">
          <div class="content-title">10.0점</div>
          <div class="content-subhead">가점</div>
        </div>
      </div>
      <!-- symbol-card -->
      <div class="symbol-card">
        <div class="card-icon"><i class="icon-unfinished"></i></div>
        <div class="card-content">
          <div class="content-title">0개</div>
          <div class="content-subhead">미수료 항목</div>
        </div>
      </div>
    </div>
    <div class="section-bottom">
      <p class="notice-icontext">획득점수 70점 이상 득점자로 상위 10%이내, 무득점 항목이 없는 경우 우수학습자 선정</p>
    </div>
  </article>
  <!-- content-section-group:자기계발 노력도, 영업활동 -->
  <div class="content-section-group group-margin">
    <!-- content-section:자기계발 노력도 -->
    <article class="content-section">
      <header class="section-header"><h3 class="title">자기계발 노력도</h3></header>
      <div class="segment-box segment-box-outline">
        <div class="contents-list">
          <div class="segment-contents">
            <h5>9.5<span class="sub"> / 10점</span></h5>
            <p>연수 마일리지</p>
          </div>
          <div class="segment-contents">
            <h5>8.0<span class="sub"> / 10점</span></h5>
            <p>신규 취득자격증</p>
          </div>
        </div>
      </div>
    </article>
    <!-- content-section:영업활동 -->
    <article class="content-section">
      <header class="section-header"><h3 class="title">영업활동</h3></header>
      <div class="segment-box segment-box-outline">
        <div class="contents-list">
          <div class="segment-contents">
            <h5>29.5 <span class="sub">/ 30점</span></h5>
            <p>리그테이블 실적</p>
          </div>
        </div>
      </div>
    </article>
  </div>
  <!-- content-section-group:전략적 사고, Digital 혁신성 -->
  <div class="content-section-group group-margin">
    <!-- content-section:전략적 사고 -->
    <article class="content-section">
      <header class="section-header"><h3 class="title">전략적 사고</h3></header>
      <div class="segment-box segment-box-outline">
        <div class="contents-list">
          <div class="segment-contents">
            <h5>9.5<span class="sub"> / 10점</span></h5>
            <p>회의체 등 참여실적</p>
          </div>
          <div class="segment-contents">
            <h5>8.0<span class="sub"> / 10점</span></h5>
            <p>지식제안 ・ 아이디어 제출</p>
          </div>
        </div>
      </div>
    </article>
    <!-- content-section:Digital 혁신성 -->
    <article class="content-section">
      <header class="section-header"><h3 class="title">Digital 혁신성</h3></header>
      <div class="segment-box segment-box-outline">
        <div class="contents-list">
          <div class="segment-contents">
            <h5>30.0 <span class="sub">/ 30점</span></h5>
            <p>DT활동 및 관심도</p>
          </div>
        </div>
      </div>
    </article>
  </div>
  <!-- content-section:공지사항 -->
  <div class="content-section section-margin">
    <header class="section-header">
      <h3 class="title">공지사항</h3>
      <div class="header-sub"><router-link :to="{name: 'JuniorNotice', params: {boardType: 'newZ'}}" class="more-link me-4">전체보기</router-link></div>
    </header>
    <div class="notice-list-container">
      <div class="board-list-container">
        <ul class="board-list">
          <li class="board-list-item">
            <article class="board-row">
              <router-link :to="{name: 'JuniorNoticeView', params: {comNtcSn: 1, boardType: 'newZ'}}" class="board-link"></router-link>
              <div class="board-column column-index"><em class="text">20</em></div>
              <div class="board-column column-title"><span class="title">신입행원육성프로그램 NewZ 안내문</span></div>
              <div class="board-column column-date"><span class="text">2021.6.10 14:40</span></div>
              <div class="board-column column-hit"><span class="text">50336 조회</span></div>
            </article>
          </li>
          <li class="board-list-item">
            <article class="board-row">
              <router-link :to="{name: 'JuniorNoticeView', params: {comNtcSn: 2, boardType: 'newZ'}}" class="board-link"></router-link>
              <div class="board-column column-index"><em class="text">19</em></div>
              <div class="board-column column-title"><span class="title">신입행원육성프로그램(NewZ)2021-2(36기)</span></div>
              <div class="board-column column-date"><span class="text">2021.6.10 14:40</span></div>
              <div class="board-column column-hit"><span class="text">50336 조회</span></div>
            </article>
          </li>
          <li class="board-list-item">
            <article class="board-row">
              <router-link :to="{name: 'JuniorNoticeView', params: {comNtcSn: 3, boardType: 'newZ'}}" class="board-link"></router-link>
              <div class="board-column column-index"><em class="text">18</em></div>
              <div class="board-column column-title"><span class="title">신입행원육성프로그램 NewZ 안내문</span></div>
              <div class="board-column column-date"><span class="text">2021.6.10 14:40</span></div>
              <div class="board-column column-hit"><span class="text">50336 조회</span></div>
            </article>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JuniorNewZ"
}
</script>